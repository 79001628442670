<script>
import { mapState } from 'vuex'
import SpinnerLoader from '../../SpinnerLoader.vue'
import CustomActivatorSelect from '@/components/inputs/CustomActivatorSelect.vue'
import BaseInputOutlined from '../../inputs/BaseInputOutlined.vue'
import Select from '../../inputs/Select.vue'
import { callNow, getUsers } from '@api/connectors/pbxOnline'
import { CONNECTOR_TYPES } from '@/vars/general'

export default {
    props: {
        isChange: {
            type: Boolean,
            default: false,
        },

        connector: {
            type: Object,
            default: null,
        },
    },
    components: { SpinnerLoader, Select, CustomActivatorSelect, BaseInputOutlined },
    data: () => ({
        loading: true,
        telephonyUsers: [],
        settings: null,
        testCall: {
            from: '',
            to: '',
            loading: false,
        },
    }),
    computed: {
        ...mapState('project', ['loggedAddingConnector', 'connectorData']),
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        testCallBtnDisabled() {
            return !this.testCall.from || !this.testCall.to
        },
        currentConnector() {
            return this.isChange ? this.connector : this.loggedAddingConnector
        },
        testCallUsers() {
            return this.project.users.map(el =>
                Object.assign({}, el, { disabled: !this.settings?.usersMapping[el.id] })
            )
        },
      telephonyUsersForSelect() {
            return this.telephonyUsers.data.map((user) => ({
                Login: user.num,
                Name: user.name || user.num,
            }));
        },
    },
    methods: {
        cancel() {
            this.$parent.$emit('cancel')
        },
        goToProject() {
            const projectId = this.$router.currentRoute.params.id
            this.$router.push(`/project/${projectId}#connectors`)
        },
        deleteUserMapping(userId) {
            this.settings = _.omit({ ...this.settings }, `usersMapping.${userId}`)
        },
        changeCallFrom(selectedUser) {
            this.testCall.from = this.settings.usersMapping[selectedUser];
        },
        async makeTestCall() {
            const projectId = this.$router.currentRoute.params.id
            await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: CONNECTOR_TYPES.pbxOnline,
            })
          const connectorForTestCall = this.connectorData || this.project.connectors
              .find(({ connectorType }) => connectorType === CONNECTOR_TYPES.pbxOnline);

            this.testCall.loading = true
            setTimeout(() => {
                this.testCall.loading = false
            }, 60 * 1000)
            const { data, error } = await callNow({
                connectorId: connectorForTestCall.connectorId,
                to: this.testCall.to.replace(/[^0-9]/g, ''),
                from: this.testCall.from,
                domain: connectorForTestCall.domain,
            })

            if (error) {
                if (error.comment === 'There is no registered user and no push token') {
                    this.$store.dispatch('callNotify', 'Ошибка. Ваша телефония не работает. Включите ваше устройство/приложение телефонии для звонка.')
                } else if (error.comment === 'NO_ANSWER') {
                    this.$store.dispatch('callNotify', 'Ошибка. Оператор не отвечает.')
                } else {
                    this.$store.dispatch('callNotify', 'Ошибка: ' + error.comment)
                }

                this.testCall.loading = false;
            }
        },
        async save() {
            if (!this.isChange) {
                this.$parent.$emit('createConnector', { settings: this.settings })
            } else {
                this.$parent.$emit('changeSettings', {
                    settings: this.settings,
                })
            }
        },
    },
    async created() {
        this.loading = true
        this.settings = { usersMapping: { ...this.currentConnector.usersMapping } || {} }
        this.telephonyUsers = await getUsers(this.currentConnector.connectorId, this.currentConnector.domain).then(res => res.data)
        this.testCall.from = this.telephonyUsersForSelect[0].Login
        this.loading = false
    },
}
</script>

<template>
    <div class="settings">
        <SpinnerLoader v-if="loading"></SpinnerLoader>
        <div v-else>
            <div class="user my-6" v-for="dhUser in project.users" :key="dhUser.id">
                <span class="user-label mr-12">{{ dhUser.name }}</span>
                <Select
                    dense
                    hide-details
                    class="user-input"
                    :selectionMaxLength="100"
                    :filled="false"
                    item-text="Name"
                    item-value="Login"
                    v-model="settings.usersMapping[dhUser.id]"
                    :items="telephonyUsersForSelect"
                    label="Пользователь телефонии"
                ></Select>
                <v-btn icon @click="deleteUserMapping(dhUser.id)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
            <div class="my-12 test-call">
                <h2 class="dashboard">Сделать тестовый звонок</h2>
                <div>

                    <v-row class="mx-0 my-4" align="center">
                        <div class="test-call__label-wrapper">
                            <span>Номер телефона получателя</span>
                        </div>
                        <BaseInputOutlined
                            v-model="testCall.to"
                            label="+79991234567"
                            hide-details
                        ></BaseInputOutlined>
                    </v-row>
                    <v-row class="mx-0 my-4" align="center">
                        <div class="test-call__label-wrapper">
                            <span>От имени пользователя</span>
                        </div>
                        <CustomActivatorSelect
                            v-model="testCall.userId"
                            :items="testCallUsers"
                            :multiple="false"
                            @input="changeCallFrom"
                        >
                            <template v-slot:activator="{ selected, _id }">
                                <BaseInputOutlined
                                    label="Пользователь"
                                    hide-details
                                    class="select-input"
                                    append-icon="mdi-menu-down"
                                    :id="_id"
                                    :value="selected ? selected.name : ''"
                                    readonly
                                >
                                </BaseInputOutlined>
                            </template>
                        </CustomActivatorSelect>
                    </v-row>
                </div>

                <v-btn
                    @click="makeTestCall"
                    :disabled="testCallBtnDisabled"
                    color="main"
                    class="mt-6 white--text"
                    :loading="testCall.loading"
                >
                    Позвонить
                </v-btn>
            </div>
            <v-row justify="end">
                <v-btn @click="save" color="main" class="mr-3 mt-6 white--text">Сохранить</v-btn>
                <v-btn v-if="isChange" @click="cancel" outlined class="mr-3 mt-6 outlined-btn">Отмена</v-btn>
                <v-btn v-if="!isChange" @click="cancel" outlined class="mr-3 mt-6 outlined-btn"
                >Не добавлять источник</v-btn
                >
            </v-row>
        </div>
    </div>
</template>

<style lang="sass" scoped>
.user
    display: flex
    justify-content: space-between
    align-content: center
.user-label
    width: 500px
    display: inline-flex
    align-items: flex-end
.user-input
    width: 300px

.test-call
    &__label-wrapper
        width: 400px
</style>
